<template>
  <span>
    <Icon :icon="icon" :width="width" />
  </span>
</template>
<script>
import { Icon } from '@iconify/vue';
export default {
  components: {
    Icon,
  },
  props: {
    icon: {
      type: String,
      default: 'heroicons-outline:home',
    },
    width: {
      type: Number,
      default: 16,
    },
  },
};
</script>
<style lang=""></style>
