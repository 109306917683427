import { createStore } from "vuex";
import chat from "./app/chat";
import email from "./app/email";
import kanban from "./app/kanban";
import project from "./app/project";
import apptodo from "./app/todo";
export default createStore({
  state: {
    sidebarCollasp: false,
    sidebarHidden: false,
    mobileSidebar: true,
    semidark: false,
    semiDarkTheme: "semi-light",
    isDark: true,
    skin: "default",
    theme: "light",
    isOpenSettings: false,
    cWidth: "full",
    menuLayout: "horizontal",
    navbarType: "sticky",
    footerType: "static",
    chartColors: {
      title: "red",
    },
    currentUser: {},
  },
  getters: {
    theme: (state) => state.theme,
    skin: (state) => state.skin,
    currentUser: (state) => state.currentUser,
  },
  mutations: {
    setSidebarCollasp(state) {
      state.sidebarCollasp = !state.sidebarCollasp;
    },

    toogleDark(state) {
      state.isDark = !state.isDark;
      state.theme = state.theme === "dark" ? "light" : "dark";
      document.body.classList.toggle(state.theme);
      localStorage.setItem("theme", state.theme);
    },

    toggleSettings(state) {
      state.isOpenSettings = !state.isOpenSettings;
    },
    toggleMsidebar(state) {
      state.mobileSidebar = !state.mobileSidebar;
    },
    toggleSemiDark(state) {
      state.semidark = !state.semidark;
      state.semiDarkTheme = state.semidark ? "semi-dark" : "semi-light";
      document.body.classList.toggle(state.semiDarkTheme);
      localStorage.setItem("semiDark", state.semidark);
    },
    setCurrentUser(state, data) {
      state.currentUser = data;
      localStorage.setItem("currentUser", JSON.stringify(data));
    },
    setRememberLoginFormateur(state, data) {
      localStorage.setItem("rememberLoginFormateur", data.login);
      localStorage.setItem("rememberPassFormateur", data.password);
    },
    setRememberLoginUtilisateur(state, data) {
      localStorage.setItem("rememberLoginUtilisateur", data.login);
      localStorage.setItem("rememberPassUtilisateur", data.password);
    },
  },
  actions: {
    // toogleDark
    toogleDark({ commit }) {
      commit("toogleDark");
    },
    // toggleSettings
    toggleSettings({ commit }) {
      commit("toggleSettings");
    },
    // setSidebarCollasp
    setSidebarCollasp({ commit }) {
      commit("setSidebarCollasp");
    },
    // toggleMsidebar
    toggleMsidebar({ commit }) {
      commit("toggleMsidebar");
    },
    toggleSemiDark({ commit }) {
      commit("toggleSemiDark");
    },
    setCurrentUser({ commit }, data) {
      commit("setCurrentUser", data);
    },
    setRememberLoginFormateur({ commit }, data) {
      commit("setRememberLoginFormateur", data);
    },
    setRememberLoginUtilisateur({ commit }, data) {
      commit("setRememberLoginUtilisateur", data);
    },
    // setTheme
  },
  modules: {
    apptodo,
    project,
    kanban,
    email,
    chat,
  },
});
