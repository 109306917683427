<template>
  <div>
    <router-view />
    <Modal
      title="Basic Modal"
      label="Modal title"
      :activeModal="showModal"
      @close="showModal = false"
    >
      Lorem ipsum dolor sit amet, consec tetur adipiscing elit, sed do eiusmod
      tempor incididun ut .
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";

export default {
  components: {
    Modal,
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    activeModal() {
      this.showModal = true;
    },
  },
  mounted() {},
};
</script>

<style></style>
